import React from 'react'
import { graphql } from 'gatsby'

// Components

// import Container from '../components/Container'
import ColourFillSection from '../components/ColourFillSection'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import Seo from '../components/Seo'
import Text from '../components/Text'
import TextList from '../components/TextList'
import ScrollSection from '../components/ScrollSection'
import DividingLine from '../components/DividingLine'

const IndexPage = ({ data }) => {
  const {
    heroTitle,
    heroButton,
    colorFillSectionSubheading,
    colorFillSectionHeading,
    colorFillSectionPreviewCopy,
    colorFillSectionMainPopUpCopy,
    centeredTextSectionSubheading,
    centeredTextSectionHeading,
    centeredTextSectionCopy,
    centeredTextSectionButton,
    featuredUseCasesSubheading,
    featuredUseCasesHeading,
    featuredUseCasesButton,
    featuredUseCasesUseCases,
    seoTitle,
    seoMetaDescription,
  } = data.allContentfulPageHome.edges[0].node

  return (
    <>
      <Seo
        title={seoTitle ? seoTitle : `Home`}
        description={seoMetaDescription}
      />

      <ScrollSection>
        <>
          <Hero home={true} title={heroTitle} button={heroButton} />
          <DividingLine />
        </>
      </ScrollSection>
      <ColourFillSection
        theme={`pink`}
        subheading={colorFillSectionSubheading}
        heading={colorFillSectionHeading}
        previewCopy={colorFillSectionPreviewCopy}
        popupCopy={colorFillSectionMainPopUpCopy}
      />
      <ScrollSection>
        <DividingLine />
      </ScrollSection>
      <ScrollSection>
        <Text
          layout={`centered`}
          subheading={centeredTextSectionSubheading}
          heading={centeredTextSectionHeading}
          copy={centeredTextSectionCopy}
          button={centeredTextSectionButton}
        />
      </ScrollSection>
      <ScrollSection>
        <DividingLine />
      </ScrollSection>
      <TextList
        home={true}
        subheading={featuredUseCasesSubheading}
        heading={featuredUseCasesHeading}
        button={featuredUseCasesButton}
        useCases={featuredUseCasesUseCases}
      />

      <ScrollSection>
        <Footer />
      </ScrollSection>
    </>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomePageQuery {
    allContentfulPageHome(limit: 1, filter: { slug: { eq: "/" } }) {
      edges {
        node {
          contentful_id
          slug
          seoTitle
          seoMetaDescription {
            seoMetaDescription
          }
          heroTitle
          heroButton {
            buttonText
            buttonLink
          }
          colorFillSectionSubheading
          colorFillSectionHeading
          colorFillSectionPreviewCopy {
            raw
          }
          colorFillSectionMainPopUpCopy {
            raw
          }
          centeredTextSectionSubheading
          centeredTextSectionHeading
          centeredTextSectionCopy {
            raw
          }
          centeredTextSectionButton {
            buttonText
            buttonLink
          }
          featuredUseCasesSubheading
          featuredUseCasesHeading
          featuredUseCasesButton {
            buttonText
            buttonLink
          }
          featuredUseCasesUseCases {
            useCaseTitle
            isThisSupportedOrUnsupported
            useCaseContent {
              raw
              references {
                ... on ContentfulAsset {
                  contentful_id
                  __typename
                  description
                  fluid(
                    cropFocus: CENTER
                    resizingBehavior: FILL
                    quality: 65
                    maxWidth: 1200
                  ) {
                    aspectRatio
                    src
                    srcSet
                    sizes
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
