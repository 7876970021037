import React, { useState, useEffect, useContext } from 'react'
import { Heading3, Heading2 } from '../../styles/vars/textStyles.style'

// Components
import { StoreDispatch } from '../../Store'
import Container from '../Container/index'
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText/index'
import ScrollSection from '../ScrollSection'
import TextModal from '../TextModal/index'

import { SectionDiv, OpenButton } from './index.style'

import IIcon from '../svgs/I/index'
import AnimateFadeIn from '../animation/AnimateFadeIn'

const Section = ({ subheading, heading, previewCopy, popupCopy }) => {
  const dispatch = useContext(StoreDispatch)
  const [modalOpen, setModalOpen] = useState(false)

  const sectionDelay = 0.5

  useEffect(() => {
    if (modalOpen === true) {
      if (window.innerWidth <= 1024) {
        // if (
        //   !document.querySelector('body').classList.contains('disable-scroll')
        // ) {
        document.querySelector('body').classList.add('disable-scroll')
        // }
      } else if (window?.scroll?.stop) {
        window.scroll.stop()
      }
      dispatch({ type: 'SHOW_MODAL' })
      dispatch({ type: 'CIRCLE_GROW' })
    }

    if (modalOpen === false) {
      dispatch({ type: 'CIRCLE_SHRINK' })
      dispatch({ type: 'HIDE_MODAL' })
      if (window.innerWidth <= 1024) {
        if (
          document.querySelector('body').classList.contains('disable-scroll')
        ) {
          document.querySelector('body').classList.remove('disable-scroll')
        }
      } else if (window?.scroll?.update) {
        window.scroll.update()
        window.scroll.start()
      }
    }
  }, [modalOpen, dispatch])

  useEffect(() => {
    const checkKey = e => {
      if (modalOpen) {
        if (e.key === 'Escape' || e.code === 'Escape') {
          setModalOpen(false)
        }
      }
    }

    window.addEventListener('keyup', checkKey)

    return () => {
      window.removeEventListener('keyup', checkKey)
    }
  }, [modalOpen, setModalOpen])

  function setOpen() {
    setTimeout(() => {
      return setModalOpen(!modalOpen)
    }, 50)
  }

  return (
    <>
      <ScrollSection>
        <SectionDiv id={`colour-block`}>
          <Container>
            <Grid>
              <GridItem mobile={16} tabletL={3} tabletLStart={3}>
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading3 as={`h3`}>{subheading}</Heading3>
                </AnimateFadeIn>
              </GridItem>
              <GridItem
                mobile={16}
                tabletL={6}
                tabletLStart={9}
                desk={5}
                deskStart={9}
                deskL={4}
                deskLStart={10}
              >
                <AnimateFadeIn delay={sectionDelay}>
                  <Heading2 as={`h2`}>{heading}</Heading2>
                  <OpenButton onClick={setOpen} aria-label={`Open Modal`}>
                    {/* <img src={iPng} alt="" height={40} width={40} /> */}
                    <IIcon />
                  </OpenButton>
                  <RichText content={previewCopy} />
                </AnimateFadeIn>
              </GridItem>
            </Grid>
          </Container>
        </SectionDiv>
      </ScrollSection>
      <TextModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        tabIndex={modalOpen ? -1 : 1}
        aria-live="polite"
        subheading={subheading}
        heading={heading}
        popupCopy={popupCopy}
      />
    </>
  )
}

export default Section
