import React from 'react'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'
import AnimateFadeIn from '../animation/AnimateFadeIn'
import Button from '../Button'

// Components
import Grid from '../Grid/index'
import GridItem from '../GridItem/index'
import RichText from '../RichText'

import { TextSection } from './index.style'

const Text = ({ layout, subheading, heading, copy, button }) => {
  const sectionDelay = 0.5

  return (
    <TextSection layout={layout}>
      <Grid>
        <GridItem
          mobile={16}
          tabletL={10}
          tabletLStart={4}
          deskL={8}
          deskLStart={5}
        >
          <AnimateFadeIn delay={sectionDelay}>
            <Heading3 as={`h3`}>{subheading}</Heading3>
            <Heading2 as={`h2`}>{heading}</Heading2>
            <RichText content={copy} />
            <Button
              text={button.buttonText}
              to={button.buttonLink}
              theme={2}
              hasborder
            />
          </AnimateFadeIn>
        </GridItem>
      </Grid>
    </TextSection>
  )
}

export default Text
