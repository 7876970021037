import styled from 'styled-components'
import { colors } from '../../styles/vars/colors.style'
import { easings } from '../../styles/vars/easings.style'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading3 } from '../../styles/vars/textStyles.style'

export const SectionDiv = styled.div`
  padding-bottom: 9rem;
  margin-top: 9rem;

  /* ${Heading3} {
    margin-bottom: 4rem;
  } */

  ${mq.desk} {
    padding-bottom: 15.8rem;
    margin-top: 15rem;
  }

  h2 {
    max-width: 80%;

    ${mq.tabletL} {
      max-width: none;
    }
  }
`

export const OpenButton = styled.button`
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 2.5rem;
  position: absolute;
  right: 0;
  top: 0rem;
  transition: background-color 1s ${easings.modal.default};
  width: 2.5rem;

  ${mq.desk} {
    height: 4rem;
    right: -5.5rem;
    width: 4rem;
  }

  svg {
    height: 100%;
    width: 100%;

    path {
      transition: fill 1s ${easings.modal.default};
    }
    circle {
      transition: stroke 1s ${easings.modal.default};
    }
  }

  &:hover {
    background: ${colors.light};
    svg {
      path {
        fill: #c88885;
      }
      circle {
        stroke: #c88885;
      }
    }
  }
`
