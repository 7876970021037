import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { Heading2, Heading3 } from '../../styles/vars/textStyles.style'
import { Button } from '../Button/index.style'

export const TextSection = styled.div`
  ${({ layout }) => {
    return layout === 'centered' ? `text-align: center;` : ``
  }}


  padding-top: 15vmin;
	margin-bottom: 20vmin;

    ${mq.deskL} {
      margin-bottom:  30rem;
      padding-top: 20rem;
    }
  }

  ${Heading3} {
    margin-bottom: 3rem;
  }

  ${Heading2} {
    margin-bottom: 2rem;

    ${mq.desk} {
      margin-bottom: 6rem;
    }
  }

  ${Button} {
    margin-top: 7rem;
  }

  p {
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
  }
`
